import React, { Component } from 'react'  

import {api_url} from '../Config.js';

import axios from 'axios';  

import ReactHTMLTableToExcel from 'react-html-table-to-excel';  

export class ExportExcel extends Component {  

        constructor(props) {  

                super(props)  

                this.state = {  

                        ProductData: []  

  

                }  

        }  

        componentDidMount() {  

                axios.get(api_url.base_url+'Welcome/getAllProducts').then(response => {  

                        console.log(response);  

                        this.setState({  

                                ProductData: response.data.product  

                        });  
         });  

        }  

        render() {  

                return (  

                        <div>  

                                <table id="emp" class="table">  

                                     <thead>  

                                                <tr>  

                                                        <th>Id</th>  

                                                        <th>Name</th>  

                                                        <th>Age</th>  

                                                        <th>Address</th>  

                                                        <th>City</th>  

                                                        <th>ContactNum</th>  

                                                        <th>Salary</th>  

                                                        <th>Department</th>  

  

  

  

                                                </tr>  

                                        </thead>  

                                        <tbody>              {  

                                                this.state.ProductData.map((p, index) => {  

                                                        return <tr key={index}>  

                                                                <td>  

                                                                        {p.Id}  

                                                                </td>  

                                                                <td >{p.name}</td>  

                                                                <td >{p.id}</td>  

                                                                <td >{p.geo_location_address}</td>  

                                                                <td >{p.city}</td>  

                                                                <td >{p.mobile}</td>  

                                                                <td >{p.email}</td>  

                                                                <td style={{ paddingRight: "114px" }} >{p.time_selection}</td>  

                                                        </tr>  

                                                })  

                                        }  

  

                                        </tbody>  

  

                                </table>  

                                <div>  
                                        <ReactHTMLTableToExcel  

                                                className="btn btn-info"  

                                                table="emp"  

                                                filename="ReportExcel"  

                                                sheet="Sheet"  

                                                buttonText="Export excel" />  

                                </div>  

                        </div>  

                )  

        }  

}  

  

export default ExportExcel  