import React from 'react';
import Header from '../component/Header';
import Footer from '../component/Footer';
import {api_url} from '../Config.js';
import loader from '../assets/images/770.gif';
import jsPDF from "jspdf";
import "jspdf-autotable";
import $ from 'jquery';
import {Redirect} from 'react-router-dom';
export default function Basic() {
  const [datatable, setDatatable] = React.useState([]);
  const [allusers, setallusers] = React.useState([]);
  const [limit, setLimit] = React.useState(10);
  const [offset, setOffset] = React.useState(1);
  const [offsetCount, setOffsetCount] = React.useState(1);
    const [loading, setLoading] = React.useState(false);
    const [redirect, setRedirect] = React.useState(false);
    const [user_id, setUser_id] = React.useState('');
    React.useEffect(() => {
        getAllUsers();
        
    },[]);

    const getAllUsers = () =>{
        setLoading(true);  
        const apiUrl = api_url.base_url+'Api/getAllUsers/'+limit+'/'+offset;    
        fetch(apiUrl)
        .then(response=>response.json())
        .then(result => {            
            setDatatable(result.users);
            setallusers(result.allusers);
            setLoading(false);  
            setOffsetCount(offsetCount+1)
        }

        )
    }
    const getOrders = (state) =>{
        console.log(state)
        if(state==='prev')
        {
            if(offset > 10){
            setOffset(offset-10);
            getAllUsers();                
            }
        }
        else if(state==='next')
        {
            setOffset(offset+10);
            getAllUsers();
        }
        else{
            setOffset(state*10);
            getAllUsers();
        }
    }
  


const downloadPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Print of users";
    const headers = [["ID", "Name","Email","Mobile","City"]];

    const data = allusers.map(elt=> [elt.id,elt.name, elt.email,elt.mobile,elt.city]);

    let content = {
      startY: 50,
      head: headers,
      body: data
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("report.pdf")
  }

  const getHisOrders = (user_id) =>{
    setUser_id(user_id);
    setRedirect(true);
    
  }

  if(redirect)
  {
    return (<Redirect
            to={{
            pathname: "/orders",
            state: { user_id: user_id }
          }}
        />)
  }

  return(
  	<React.Fragment>
  	<Header />
    <div className="table_res">
  	 <table className="table table-hover">
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Email</th>
            <th>Mobile</th>
            <th>City</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
        {
            loading ? 
            <div style={{textAlign:'center'}}>
                <img src={loader} alt="loader" />
            </div>
             :
            datatable.map(user=>(
          <tr key={user.id}>
            <td>{user.id}</td>
            <td>{user.name}</td>
            <td>{user.email}</td>
            <td>{user.mobile}</td>
            <td>{user.city}</td>
            <td><button className="btn btn-default" onClick={()=>getHisOrders(user.id)}>View Orders</button></td>
          </tr>
            ))
        }
          
          
        </tbody> 
     </table>
     </div>
        <div>
            <div className="col-lg-8">
                <ul className="pagination">
                  <li className="page-item" disabled={true}><a className="page-link"  style={{cursor:'pointer'}} onClick={()=>{getOrders('prev')}}>Previous</a></li>
                  <li className="page-item"><a className="page-link" style={{cursor:'pointer'}} onClick={()=>{getOrders(offsetCount)}}>{offsetCount}</a></li>
                  <li className="page-item"><a className="page-link" style={{cursor:'pointer'}} onClick={()=>{getOrders(offsetCount+1)}}>{offsetCount+1}</a></li>
                  <li className="page-item"><a className="page-link" style={{cursor:'pointer'}} onClick={()=>{getOrders(offsetCount+2)}}>{offsetCount+2}</a></li>
                  <li className="page-item"><a className="page-link" style={{cursor:'pointer'}} onClick={()=>{getOrders('next')}}>Next</a></li>
                </ul>
            </div>
            <div className="col-lg-4 text-right">
                <button className="btn btn-default" onClick={()=>downloadPDF()}>Downlod</button>
            </div>
        </div>
  	<Footer />
  	</React.Fragment>
  	) ;
}



