import React, { Component } from 'react';
import {api_url} from '../Config.js';
import $ from 'jquery';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import fruitivan from '../assets/images/fruitivan.png';
import firebase from '../component/fbConfig';
export class MapContainer extends Component {
    constructor(props) {
    super(props);
        this.state = {
          stores: [],
          username:''
        }        
    }
   
    componentDidMount() {
        var pushdata = [];
        const obj =this;
          var database = firebase.database();
            var data =  firebase.database().ref('location').once('value').then(function(snapshot) {
          });
          //event read
          var starCountRef = firebase.database().ref('location');
          starCountRef.on('value', function(snapshot) {

          });

          var commentsRef = firebase.database().ref('location');
          commentsRef.on('child_added', function(data) {
          });

          commentsRef.endAt().limitToLast(1).on('child_added', function(snapshot) {

          var arr = {lat:snapshot.val().lat,lng:snapshot.val().long,uid:snapshot.val().uid,uname:snapshot.val().uname};
     
          if(snapshot.val().uid > 0)
          {
            var uid = snapshot.val().uid;
            //check if in array
            var find = 0;
            for(var i in pushdata)
            {
              var row = pushdata[i];
              if(row.uid == snapshot.val().uid)
              {
                find = 1;
                if(pushdata[i].lat != arr.lat && pushdata[i].lng != arr.lng)
                {
                  pushdata[i].lat = arr.lat;
                  pushdata[i].lng = arr.lng;
                }


                if(pushdata[i].lat == 0 && pushdata[i].lng == 0)
                {
                  delete pushdata[i];
                  // alert(pushdata[i].uname+"Location change")
                }
                
              }
            }
            if(!find)
            {
              // alert("New user");

            pushdata.push(arr);
            update_markder(obj,pushdata);
            }

          }
           
           // sessionStorage.setItem('stores',JSON.stringify(pushdata));
          setInterval(function(){
           update_markder(obj,pushdata);
           console.log("pushdata");
            console.log(pushdata);

         }, 5000);
          
        });

          commentsRef.on('child_changed', function(data) {
          });

          commentsRef.on('child_removed', function(data) {
          });
          function update_markder(obj,stores){
            obj.setState({
                stores:stores
             },function(){
             })

          }
         
        
    }
    render() {
        const {stores} = this.state;
        // console.log('state updateed ' +this.state.stores)
    const mapStyles = {
          width: '100%',
          height: '100vh',
          overflowY: 'hidden'
    };
    const { latitude, longitude } = this.state;
    return (
        <React.Fragment>
            <Map
                google={this.props.google}
                zoom={13}
                style={mapStyles}
                initialCenter={{ lat: 33.6844, lng:73.0479}}
            >
                {
                stores && stores.map((store,index)=>{
                return <Marker  key={index} icon={fruitivan} title={store.uname} position={{
                    lat:store.lat,
                    lng: store.lng
                }}
                onClick={() => alert(store.uname)} /> 
                }) 
                   
                }
            </Map>
        </React.Fragment>
    );
  }
}
export default GoogleApiWrapper({
  apiKey: 'https://maps.googleapis.com/maps/api/geocode/json?latlng=33.6844,73.0479&key=AIzaSyDJoTnvdGgAuOLEBLbjQsxyqy8r3pY5I7g'
})(MapContainer);