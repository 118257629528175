import React, { Component } from 'react';
import loading from '../assets/images/770.gif';
import {NavLink} from 'react-router-dom';
import Header from '../component/Header';
import Footer from '../component/Footer';
import {Redirect} from 'react-router-dom';
import {api_url} from '../Config.js';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
class User extends Component {
  constructor(props) {
    super(props);
    this.state = {
        allorder:[],
        offset: 0,
        data: [],
        perPage: 10,
        currentPage: 0,
        redirectToReferrer: false,
        base_url:api_url.base_url,
        img_url:api_url.img_url,
        com_url:api_url.com_url,
        user_id:'',
        loading:false,
        slice:[],
        startDate:null,
        endDate:null,
        pLoading:false,
        redirect:false,
        user_id:'',
        success_message:(localStorage.getItem('success')) ? localStorage.getItem('success'):'',
    };
    this.handlePageClick = this.handlePageClick.bind(this);
    this.getAllOrders = this.getAllOrders.bind(this);
    this.getOrdersbyDate = this.getOrdersbyDate.bind(this);
  }
  componentDidMount(){
    this.receivedData();
    if(localStorage.getItem('success'))
    {
      localStorage.removeItem('success');
    }
    this.getAllOrders();
  }
  componentWillMount() {
  if(sessionStorage.getItem("userData")){
      this.getUserData();
    }
    else
    {
      this.setState({redirectToReferrer: true});
    }
  }
  getUserData() {
    JSON.parse(sessionStorage.getItem("userData"));
  }
async getAllOrders(){
        var date = new Date();
        var d = date.getDate();
        var m = date.getMonth();
        var y = date.getFullYear();
        var month = m+parseFloat(1);
        var formatedDate = y+'-'+month+'-'+d;
        var todDate = y+'-'+m+'-'+d;
        const fromDate = (this.state.startDate===null) ? formatedDate : this.state.startDate;
        const toDate = (this.state.endDate===null) ? formatedDate : this.state.endDate;
  const caturl = api_url.base_url+'Api/getAllUsers/'+fromDate+'/'+toDate;
  this.setState({loading:true})
    await fetch(caturl)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            allorder:result.users,
            loading:false,
          });
        },
        (error) => {
          this.setState({loading:false, pLoading:false});
        }
      )
}
receivedData() {
        this.setState({pLoading:true});
        var date = new Date();
        var d = date.getDate();
        var m = date.getMonth();
        var y = date.getFullYear();
        var month = m+parseFloat(1);
        var toFormateDate = y+'-'+month+'-'+d;
        var FromFormatedDate = y+'-'+m+'-'+d;
        const fromDate = (this.state.startDate===null) ? FromFormatedDate : this.state.startDate;
        const toDate = (this.state.endDate===null) ? toFormateDate : this.state.endDate;
    axios
        .get(api_url.base_url+'Api/getAllUsers/'+fromDate+'/'+toDate)
        
        .then(res => {
          if(res.data.users)
            {
              const data = res.data.users;
              const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
            

              this.setState({
                  pageCount: Math.ceil(data.length / this.state.perPage),
                  slice:slice,
                  pLoading:false
              })
            }else{
              this.setState({pLoading:false})
            }
        });
}
handlePageClick (e) {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState({
        currentPage: selectedPage,
        offset: offset
    }, () => {
        this.receivedData()
    });

};

 searchWithDate = (date) =>{
        this.setState({loading:true});
        const apiUrl = api_url.base_url+'Welcome/getProductByDate/'+date;      
        fetch(apiUrl)
        .then(response=>response.json())
        .then(result => {
            if(result){
                // setDate(null);
                this.setState({loading:false});
            }else{
                this.setState({loading:false});
            }
         
            
        },
        (error) => {
            this.setState({loading:false, pLoading:false});
          }

        )
     }

  startDate = (date)=>{
        var d = date.getDate();
        var m = date.getMonth();
        var y = date.getFullYear();
        var month = m+parseFloat(1);
        var formatedDate = y+'-'+month+'-'+d;
        this.setState({startDate:formatedDate});
     }
     endDate = (date)=>{
        var d = date.getDate();
        var m = date.getMonth();
        var y = date.getFullYear();
        var month = m+parseFloat(1);
        var formatedDate = y+'-'+month+'-'+d;
        this.setState({endDate:formatedDate});
     }

     getOrdersbyDate (event) {
        this.receivedData();
     }
     
 getHisOrders = (user_id) =>{
  console.log(user_id);
    this.setState({user_id:user_id});
    this.setState({redirect:true});
    
  }

  
render() {
  if(this.state.redirect)
  {
    return (<Redirect
            to={{
            pathname: "/order",
            state: { user_id: this.state.user_id }
          }}
        />)
  }
  if (this.state.redirectToReferrer) {
  return (<Redirect to={"/" }/>)
}
  return (
      <div>
      <Header />
      {this.state.pLoading && 
        <div className="pLoading"><p>Loading...</p></div>
      }
         <div className="user_profile_wrap">
            <div className="export-pdf">
            <div className="row" style={{marginTop:"10px"}}>
              <div className="col-sm-12">
              <form>
                  <ReactHTMLTableToExcel  

                    className="search-bt  btn btn-default"  

                    table="customers"  

                    filename="ReportExcel"  

                    sheet="Sheet"  

                    buttonText="Export to excel" />
                  <DatePicker 
                  value={this.state.startDate}
                  placeholderText="from date.."
                  onChange={(e)=>this.startDate(e)}
                />
              <DatePicker 
                  value={this.state.endDate}
                  placeholderText="to date.."
                  onChange={(e)=>this.endDate(e)}
                />
                <input className=" search-bt  btn btn-default" type="button" value="Search"  onClick={()=>this.getOrdersbyDate()} />
                </form>
              </div>
              
          </div>
              <div className="row">
                {(
                  this.state.success_message!='' ? 
                  <div className="col-sm-12">
                    <div className="alert alert-success">
                      {this.state.success_message}
                    </div>
                  </div>
                  :""
                )}
              </div>
               <div className="row">
                  
                  <div className="col-sm-12 accont_right_layout">
                     <h3>All Users List</h3>
                     

                     
                     <div className="form-wrapper-trad">
                        <div className="row">
                           <div className="col-lg-12 padding-set-left orderlist-table">
                              

                                {(this.state.loading)?
                              <div style={{textAlign:'center',marginTop:'70px'}}>
                              <img src={loading} /></div>:
                              <div>
                              <table id="customers">

                                <thead>
                                   <tr>
                                        <th>ID</th>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Mobile</th>
                                        <th>Address</th>
                                        <th>Action</th>
                                   </tr>
                                </thead>
                                <tbody>
                                  { this.state.slice.length < 1 ? 
                                    <tr>
                                        <td colSpan="10">No record found</td>
                                    </tr>
                                   :this.state.slice.map((item,key) => 
                                  <tr className="fixed-table" key={key}>
                                <td colSpan="1">
                                      <p>{item.id}</p>
                                </td>
                                <td colSpan="1">
                                     <p>{item.name} </p>
                                </td>
                                <td colSpan="1" className="red-trash">
                                  <p>{item.email}</p>
                                </td>
                                <td colSpan="1" className="red-trash">
                                  <p>{item.mobile}</p>
                                </td>
                                <td colSpan="1" className="red-trash">
                                  <p>{item.city}</p>
                                </td>
                                <td colSpan="1" className="red-trash">
                                  <p> 
                                    <a onClick={()=>this.getHisOrders(item.id)} style={{cursor:'pointer'}} >  <i className="fa fa-eye" ></i>  View Orders</a>
                                    
                                  </p>
                                </td>
                             </tr>
                             )}
                    
                                 </tbody>

                              </table>
                                 <ReactPaginate
                                previousLabel={"Prev"}
                                nextLabel={"Next"}
                                breakLabel={"..."}
                                breakClassName={"break-me"}
                                pageCount={this.state.pageCount}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={2}
                                onPageChange={this.handlePageClick}
                                containerClassName={"pagination"}
                                subContainerClassName={"pages pagination"}
                                activeClassName={"active"}/>
                           </div>
                              }
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      <Footer />
    </div>
    );
  }
}

export default User;