import React, { Component } from 'react';
import logo from '../assets/images/green-logo.png';
import cart from '../assets/images/cart_image.png';
import ground from '../assets/images/ground-svg.svg';
import {api_url} from '../Config.js';
import {Redirect} from 'react-router-dom';
class Login extends Component {
    	constructor(){
        super();
        this.state = {
         email: '',
         password: '',
         redirectToReferrer: false,
         wrongErr:'',
         emailErr:'',
         passErr:'',
         loading:false,
        };
        this.login = this.login.bind(this);
        this.onChange = this.onChange.bind(this);
        this.validation = this.validation.bind(this);
    }
    componentWillMount() {
        if(sessionStorage.getItem('userData')){
            this.getUserData();
            this.setState({redirectToReferrer: true});
        }
        else{
            this.setState({redirectToReferrer: false});
        }
    }
    getUserData() {
        JSON.parse(sessionStorage.getItem("userData"));
    }

    login(e) {
   	    e.preventDefault();
        if(this.validation())
        {
            this.setState({emailErr:'',passErr:'', wrongErr:'',loading:true});
        fetch(api_url.base_url+'Api/login_user',
        {
            method: 'POST',
            headers:
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body:JSON.stringify(this.state)
        })
        .then((response) => response.json())
        .then(result=>{
        {
         let responseJson = result;
         if(responseJson.userData){
         	console.log(result.userData)
            sessionStorage.setItem('userData',JSON.stringify(responseJson.userData));
            this.setState({redirectToReferrer: true});
        }
        else
        this.setState({wrongErr:'Wrong Email or password',loading:false },function(){
               
                })
            }
        })
        .catch((error) => {
            this.setState({loading:false});
            });
        }
    }

    validation(){
      if(this.state.email === '' && this.state.password==='')
      {
         this.setState(
            {
               emailErr:'Please enter your email',
               passErr: 'Please enter your password',
            }
         )
      }else if(this.state.email ==='')
      {
         this.setState(
            {
               emailErr:'Please enter your email',
               passErr: '',
            }
         )
      }else if(this.state.password === '')
      {
         this.setState(
            {
               emailErr:'',
               passErr: 'Please enter your password',
            }
         )
      }else{
         return true
      }
    }



onChange(e){
   this.setState({[e.target.name]:e.target.value});
}
render() {
	   if (this.state.redirectToReferrer) {
        return (<Redirect to={'/home'}/>);
}
const {loading} = this.state;
return (
	<div className="login_bg"> 
		<div id="wrapper">
			<div id="container-fluid">
				<div id="main-wrap">
					<div className="forget_password">
						<img src={logo} alt="" />
						<div>
							<form onSubmit={this.login} className="show_form login-form">
							<span style={{color: "red"}}>{this.state.wrongErr}</span>
								<div className="form_grouphs">
									<input type="text" name="email"  placeholder="Username" onChange={this.onChange} />
									 <span style={{color: "red"}}>{this.state.emailErr}</span>
								</div>
								<div className="form_grouphs">
									<input type="password" name="password" placeholder="Password" onChange={this.onChange} />
									<span style={{color: "red"}}>{this.state.passErr}</span>
								</div>
								<button
                                   className="button-loadings"
                                   style={{ fontSize: "18px"}}
                                   type="submit"
                                   disabled={loading}
                                >
                                   {loading && (
                                     <i
                                       className="fa fa-refresh fa-spin"
                                       style={{ marginRight: "5px" }}
                                     />
                                   )}
                                   {loading && <span>Loading...</span>}
                                   {!loading && <span>Login</span>}
                                </button>
							</form>
						</div>
						<div className="cart-image">
							<img src={cart} alt="" />
						</div>
					</div>
				</div>
			</div>
			<div className="green-field ">
				<img src={ground} alt=""/>
			</div>
		</div>
	</div>
);
	}
}
export default Login;