import firebase from 'firebase';
  const config = {
     apiKey: "AIzaSyAzdoPT6jPxa-VHZwcO-nlRQGQhMO0Qe8c",
    authDomain: "eloerp-9428b.firebaseapp.com",
    databaseURL: "https://eloerp-9428b.firebaseio.com",
    projectId: "eloerp-9428b",
    storageBucket: "eloerp-9428b.appspot.com",
    messagingSenderId: "645190758385",
    appId: "1:645190758385:web:c518721c3ed9280ce71e50"
  };
  const Firebase = firebase.initializeApp(config);
  export default Firebase;