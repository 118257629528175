import React, { Component } from 'react';
import Map from '../component/Map';
import Header from '../component/Header';
import Footer from '../component/Footer';
import {Redirect} from 'react-router-dom';
class Maps extends Component {

    constructor(){
        super();
        this.state = {
            redirectToReferrer: false,
        };
    }
    componentWillMount() {
        if(sessionStorage.getItem("userData")){
            this.getUserData();
        }
        else{
            this.setState({redirectToReferrer: true});
        }
    }
    getUserData() {
        JSON.parse(sessionStorage.getItem("userData"));
    }	
    render(){
        if (this.state.redirectToReferrer) {
            return (<Redirect to={"/" }/>)
        }

    return (
    	<div style={{overFlowY:"hidden"}}>
    	    <Header />
            <Map />
            <Footer />
        </div>
    );
  }
}
export default Maps;