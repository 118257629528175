import React from 'react';
import fv_logo from '../assets/images/fv-logo.png';
import {Link} from 'react-router-dom';
const Header = () =>{
	const order=<svg viewBox="0 0 24 24"><path d="M6 5h2.5a3 3 0 1 1 6 0H17a3 3 0 0 1 3 3v11a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3V8a3 3 0 0 1 3-3zm0 1a2 2 0 0 0-2 2v11a2 2 0 0 0 2 2h11a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2h-1v3H7V6H6zm2 2h7V6H8v2zm3.5-5a2 2 0 0 0-2 2h4a2 2 0 0 0-2-2zm5.65 8.6L10 18.75l-3.2-3.2l.707-.707L10 17.336l6.442-6.443l.707.707z" fill="#fff"/></svg>;
	const users=<svg viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M11.5 14c4.142 0 7.5 1.567 7.5 3.5V20H4v-2.5c0-1.933 3.358-3.5 7.5-3.5zm6.5 3.5c0-1.38-2.91-2.5-6.5-2.5S5 16.12 5 17.5V19h13v-1.5zM11.5 5a3.5 3.5 0 1 1 0 7a3.5 3.5 0 0 1 0-7zm0 1a2.5 2.5 0 1 0 0 5a2.5 2.5 0 0 0 0-5z" fill="#fff"/></svg>;
 const map = <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-geo-alt" fill="currentColor" >
  <path fillRule="evenodd" d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
</svg>	;
const dashboard=<svg viewBox="0 0 24 24"><path d="M16 8.414l-4.5-4.5L4.414 11H6v8h3v-6h5v6h3v-8h1.586L17 9.414V6h-1v2.414zM2 12l9.5-9.5L15 6V5h3v4l3 3h-3v7.998h-5v-6h-3v6H5V12H2z" fill="#fff"/></svg>
return(

	<React.Fragment>
		<div  id="topbar" className="fixed_drag_top">
			<div className="clearfix">
				<div className="middle_logo app_logo">

					<Link to="/home" className='order_box'>{dashboard}</Link>
					<Link to="/user" className='order_box order_box2'>{users}</Link>
					<Link to="/order" className='order_box order_box3'>{order}</Link>
					<Link to="/map" className='map-icon'>{map}</Link>
					<Link to="/home"><img src={fv_logo} alt="Logo" /></Link>
				</div>

			</div>
		</div>
		


	</React.Fragment>
	)
}
export default Header