import React from 'react';
import Header from '../component/Header';
import Footer from '../component/Footer';
import {api_url} from '../Config.js';
import loader from '../assets/images/770.gif';
import jsPDF from "jspdf";
import "jspdf-autotable";
import $ from 'jquery';
export default function Basic(props) {
  const [datatable, setDatatable] = React.useState([]);
  const [allusers, setallusers] = React.useState([]);
  const [limit, setLimit] = React.useState(10);
  const [offset, setOffset] = React.useState(0);
  const [offsetCount, setOffsetCount] = React.useState(1);
    const [loading, setLoading] = React.useState(false);
    React.useEffect(() => {
        
        let user_id = '';
         if(props.location.state)
         {
         	user_id = props.location.state.user_id ;
         }
        getAllUsers(user_id);
        
    },[]);

    const getAllUsers = (user_id) =>{
        setLoading(true);  
        const apiUrl = api_url.base_url+'Api/getAllOrders/'+limit+'/'+offset+'/'+user_id;    
        fetch(apiUrl)
        .then(response=>response.json())
        .then(result => {            
            setDatatable(result.users);
            setallusers(result.allusers);
            setLoading(false);  
            setOffsetCount(offsetCount+1)
        }

        )
    }
    const getOrders = (state) =>{
        console.log(state)
        if(state==='prev')
        {
            if(offset > 10){
            setOffset(offset-10);
            getAllUsers();                
            }
        }
        else if(state==='next')
        {
            setOffset(offset+10);
            getAllUsers();
        }
        else{
            setOffset(state*10);
            getAllUsers();
        }
    }
  


const downloadPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Print of orders";
    const headers = [["OrderID", "Name","Mobile","Type","Building","Street","Floor/Unit","Sector","Order Address","Note to Rider","Assigned to","Status","Date"]];

    const data = allusers.map(elt=> [elt.master_id,elt.name, elt.mobile,elt.time_selection,elt.building,elt.street,elt.floor,elt.area_name,elt.geo_location_address,elt.note_to_rider,elt.username,elt.status_name,elt.datetime]);
    let content = {
      startY: 50,
      head: headers,
      body: data
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("report.pdf")
  }

  return(
  	<React.Fragment>
  	<Header />
    <div className="table_res">
  	 <table className="table table-hover">
        <thead>
          <tr>
            <th>Order #</th>
            <th>Name</th>
            <th>Mobile</th>
            <th>Type</th>
            <th>Building</th>
            <th>Street</th>
            <th>Floor/Unit</th>
            <th>Sector</th>
            <th>Order Address</th>
            <th>Note to Rider</th>
            <th>Assigned to</th>
            <th>Status</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
        {
            loading ? 
            <div style={{textAlign:'center'}}>
                <img src={loader} alt="loader" />
            </div>
             :
             datatable.length> 0 &&
            datatable.map(user=>(
          <tr key={user.master_id}>
            <td>{user.master_id}</td>
            <td>{user.name}</td>
            <td>{user.mobile}</td>
            <td>{user.time_selection}</td>
            <td>{user.building}</td>
            <td>{user.street}</td>
            <td>{user.floor}</td>
            <td>{user.area_name}</td>
            <td>{user.geo_location_address}</td>
            <td>{user.note_to_rider}</td>
            <td>{user.username}</td>
            <td>{user.status_name}</td>
            <td>{user.datetime}</td>
          </tr>
            ))
        }
          
          
        </tbody> 
     </table>
     </div>
        <div>
            <div className="col-lg-8">
                <ul className="pagination">
                  <li className="page-item" disabled={true}><a className="page-link"  style={{cursor:'pointer'}} onClick={()=>{getOrders('prev')}}>Previous</a></li>
                  <li className="page-item"><a className="page-link" style={{cursor:'pointer'}} onClick={()=>{getOrders(offsetCount)}}>{offsetCount}</a></li>
                  <li className="page-item"><a className="page-link" style={{cursor:'pointer'}} onClick={()=>{getOrders(offsetCount+1)}}>{offsetCount+1}</a></li>
                  <li className="page-item"><a className="page-link" style={{cursor:'pointer'}} onClick={()=>{getOrders(offsetCount+2)}}>{offsetCount+2}</a></li>
                  <li className="page-item"><a className="page-link" style={{cursor:'pointer'}} onClick={()=>{getOrders('next')}}>Next</a></li>
                </ul>
            </div>
            <div className="col-lg-4 text-right">
                <button className="btn btn-default" onClick={()=>downloadPDF()}>Downlod</button>
            </div>
        </div>
  	<Footer />
  	</React.Fragment>
  	) ;
}



