import React, { useState,useEffect } from 'react';
import {api_url} from '../Config.js';
import OrderList from './OrderList';
import loader from '../assets/images/770.gif';
import $ from 'jquery';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const Content =()=> {

    const [products, setProducts] = useState([]);
    const [status, setStatus] = useState([]);
    // const [statusValue, setStatusValue] = useState('');
    const [shops, setShops] = useState([]);
    const [editProduct, setEditProduct] = useState([]);
    const [allRiders, setAllRiders] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [date, setDate] = useState(null);
    const [mloading, setMLoading] = useState(false);
    let oldLength = 0;
    useEffect(() => {
        getAllProducts();
        $(".button_close,.popup-overlay").click(function(){
                $(".popup-overlay,.popup_inner").fadeOut();
            });
            setInterval(() => {
                getLength(oldLength);
            }, 5000);

    },[])

    
	function getAllProducts(){
        const apiUrl = api_url.base_url+'Welcome/getAllProducts';
         setLoading(true);      
        fetch(apiUrl)
        .then(response=>response.json())
        .then(result => {
            console.log(result)
            
            if(result)
            {
                setProducts(result.product);
                setStatus(result.status);
                setShops(result.shops);
                oldLength=result.product.length;

            } 
            setLoading(false);

        },
        (error) => {
            setError(error);
            setLoading(false);
          }

        )
    }
    const filterByStatus = (status_id)=>{
        setLoading(true);
        const date_now = date;
        const apiUrl = api_url.base_url+'Welcome/filterProductByStatus/'+date_now+'/'+status_id;
        fetch(apiUrl)
        .then(response=>response.json())
        .then(result => {
          
                setProducts(result.product);
                setStatus(result.status);
                setShops(result.shops);
                oldLength=result.product.length;

            
            setLoading(false);
          
        },
        (error) => {
            setError(error);
            setLoading(false);
          }
        )
    }
     const getLength=(oldLength)=>{  
        const apiUrl = api_url.base_url+'Welcome/getAllproductsLength';
        fetch(apiUrl)
        .then(response=>response.json())
        .then(result => {
          if(result.length)
          {
            compareLengths(result.length,oldLength);
            
          }
          
        },
        (error) => {
            setError(error);
            setLoading(false);
          }
        )
    }
    function compareLengths(result,oldLength){
        if(result !== oldLength)
        {
            getAllProducts();
        }
    }
    const getProductDetails = (id) =>{
        setLoading(true);
        const apiUrl = api_url.base_url+'Welcome/getAllProductsById/'+id;      
        fetch(apiUrl)
        .then(response=>response.json())
        .then(result => {
            console.log(result)
            setAllRiders(result.riders);
            setEditProduct(result.product);
            setStatus(result.status);
            setShops(result.shops);
            if(editProduct !==null)
            {
                setLoading(false);
                $(".popup-overlay,.popup_inner").fadeIn();
            }
            
        },
        (error) => {
            setError(error);
            setLoading(false);
          }

        )
     }
     const searchWithDate = (date) =>{
        setLoading(true);
        const apiUrl = api_url.base_url+'Welcome/getProductByDate/'+date;      
        fetch(apiUrl)
        .then(response=>response.json())
        .then(result => {
            if(result){
                setProducts(result.product);
                setStatus(result.status);
                setShops(result.shops);
                // setDate(null);
                setLoading(false);
            }else{
                setProducts([]);
                setLoading(false);
            }
         
            
        },
        (error) => {
            setError(error);
            setLoading(false);
          }

        )
     }
     const searchDate = (date)=>{
        var d = date.getDate();
        var m = date.getMonth();
        var y = date.getFullYear();
        var month = m+parseFloat(1);
        var formatedDate = y+'-'+month+'-'+d;
        setDate(formatedDate);
        searchWithDate(formatedDate);
     }
     const todayYesterday = (dateNow) =>{
        setLoading(true);
        setDate(dateNow)
        const apiUrl = api_url.base_url+'Welcome/todayYesterday/'+dateNow;      
        fetch(apiUrl)
        .then(response=>response.json())
        .then(result => {
            if(result){
                setProducts(result.product);
                setStatus(result.status);
                setShops(result.shops);
                setLoading(false);
            }else{
                setProducts([]);
                setLoading(false);
            }
         
            
        },
        (error) => {
            setError(error);
            setLoading(false);
          }

        )
     }

     const cancelOrder=(master_id)=>{
        setMLoading(true);
        let userData = {
            master_id:master_id,
        }
        fetch(api_url.base_url+'Api/cancelThisOrder',
        {

            method: 'POST',
            headers:
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body:JSON.stringify(userData)
        })
        .then((response) => response.json()
            .then((result) => {
                setProducts(result.product);
                searchWithDate(date);
                setStatus(result.status);
                // setShops(result.shops);
                setMLoading(false);
        }))
        .catch((error) => {
            setMLoading(false);
        });
     }
     const editShop=(rider_id,master_id)=>{
        console.log(rider_id,master_id)
        setMLoading(true);
        let userData = {
            master_id:master_id,
            rider_id:rider_id
        }
        fetch(api_url.base_url+'Welcome/editShopValue',
        {

            method: 'POST',
            headers:
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body:JSON.stringify(userData)
        })
        .then((response) => response.json()
            .then((result) => {
                setEditProduct(result.product);
                searchWithDate(date);
                setMLoading(false);
        }))
        .catch((error) => {
            setMLoading(false);
        });
     }
     const onClose = () =>{
        // setDate(null);
     }

     const Close = <svg  className="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" d="M11.854 4.146a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708-.708l7-7a.5.5 0 0 1 .708 0z"/>
          <path fillRule="evenodd" d="M4.146 4.146a.5.5 0 0 0 0 .708l7 7a.5.5 0 0 0 .708-.708l-7-7a.5.5 0 0 0-.708 0z"/>
        </svg>

     const Tick = <svg width="20px" height="20px" viewBox="0 0 16 16" className="bi bi-check" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z"/>
        </svg>

        let whtatIsOrderStatus = '';
        if(editProduct && editProduct.shop_id === '0')
        {
            whtatIsOrderStatus = editProduct.status;
         
        }else{
            if(editProduct && editProduct.shop_id === '1')
            {
                whtatIsOrderStatus = 'Canceled by Admin';
            }

            if(editProduct && editProduct.shop_id === '2')
            {
                whtatIsOrderStatus = 'Canceled by User';
            }

        }
	return (
	<React.Fragment>
    <div className="popup-overlay"></div>
 
            <div className="popup_inner">
            <div className="button_close">
             <button  onClick={()=>onClose()}> {Close}          
            </button>
           </div>
            {editProduct !=='' &&
            <div className={!mloading ? "popup_data" : "popup_data fade_in_out"}>


            <div className="row bdr_box">
                <div className="col-sm-3 main_head">
                    <b>Order # :</b>
                </div>
                <div className="col-sm-9 main_contents">
                    <p>{editProduct.master_id }</p>
                </div>
            </div>
            <div className="row bdr_box">
                <div className="col-sm-3 main_head">
                    <b>Name :</b>
                </div>
                <div className="col-sm-9 main_contents">
                    <p>{editProduct.name }</p>
                </div>
            </div>

            <div className="row bdr_box">
                <div className="col-sm-3 main_head">
                    <b>Phone Number :</b>
                </div>
                <div className="col-sm-9 main_contents">
                    <p>{editProduct.mobile }</p>
                </div>
            </div>


            <div className="row bdr_box">
                <div className="col-sm-3 main_head">
                    <b>Order Date :</b>
                </div>
                <div className="col-sm-9 main_contents">
                    <p>{editProduct.datetime }</p>
                </div>
            </div>
            <div className="row bdr_box">
                <div className="col-sm-3 main_head">
                    <b> Order Type:</b>
                </div>
                <div className="col-sm-9 main_contents">
                    <p>{editProduct.time_selection }</p>
                </div>
            </div>
            <div className="row bdr_box">
                <div className="col-sm-3 main_head">
                    <b> Building:</b>
                </div>
                <div className="col-sm-9 main_contents">
                    <p>{editProduct.building }</p>
                </div>
            </div>
            <div className="row bdr_box">
                <div className="col-sm-3 main_head">
                    <b> Street:</b>
                </div>
                <div className="col-sm-9 main_contents">
                    <p>{editProduct.street }</p>
                </div>
            </div>
            <div className="row bdr_box">
                <div className="col-sm-3 main_head">
                    <b> Floor/Unit:</b>
                </div>
                <div className="col-sm-9 main_contents">
                    <p>{editProduct.floor }</p>
                </div>
            </div>
            <div className="row bdr_box">
                <div className="col-sm-3 main_head">
                    <b> Sector:</b>
                </div>
                <div className="col-sm-9 main_contents">
                    <p>{editProduct.area_name }</p>
                </div>
            </div>
            <div className="row bdr_box">
                <div className="col-sm-3 main_head">
                    <b>Order Address :</b>
                </div>
                <div className="col-sm-9 main_contents">
                    <p>{editProduct.geo_location_address }</p>
                </div>
            </div>
            <div className="row bdr_box">
                <div className="col-sm-3 main_head">
                    <b>Note to Rider :</b>
                </div>
                <div className="col-sm-9 main_contents">
                    <p>{editProduct.noterider }</p>
                </div>
            </div>
            <div className="row bdr_box">
                <div className="col-sm-3 main_head">
                    <b>Asigned:</b>
                </div>
                <div className="col-sm-9 main_contents">
                    <p>
                     <span className={editProduct.assign_to !=="0" ? "label label-success" : "label label-default"}>{editProduct.assign_to !=="0" ? editProduct.username : "Not Assigned"}</span>
                         
                    </p>
                </div>
            </div>


            <div className="row bdr_box">
                <div className="col-sm-3 main_head">
                    <b>Asigned to :</b>
                </div>
                <div className="col-sm-9 main_contents">
                    <p>
                     {
                            allRiders.map(a=>(
                                    <span key={a.id} className={a.id===editProduct.assign_to ? "label label-success" : "label label-default"} onClick={()=>editShop(a.id,editProduct.id)}>{a.username} {a.id===editProduct.assign_to&& Tick}</span>
                                ))
                        }
                         
                    </p>
                </div>
            </div>
            <div className="row bdr_box">
                <div className="col-sm-3 main_head">
                    <b>Status:</b>
                </div>
                <div className="col-sm-9 main_contents">
                    {whtatIsOrderStatus
                    }
                </div>
            </div>
            <div className="row bdr_box">
               <div className="col-sm-3 main_head">
                </div>
                <div className="col-sm-9 main_contents">
                    <p>
                     
                     <button type="button" className="btn btn-danger"  onClick={()=>cancelOrder(editProduct.id)}>Cancel</button>
                         
                    </p>
                </div>
            </div>

            </div>
            }
            
            </div>
		<section className="backing" id="pos_screen">
            <div id="main-wrap">
            
                <div className="products-box multi_box_bg">
                        <div className="clearfix">
                            <div className="color_code">
                            <div className="row">
                            <div className="col-sm-6 ">
                            <div className="color_code">

                            {
                                status.map(status=>{
                                    let dynamicClass = "";
                                    if(status.id==='2')
                                    {
                                        dynamicClass = "green_bg_color";
                                    }else if(status.id==='3')
                                    {
                                        dynamicClass = "blue_bg_color";
                                    }else if(status.id==='4')
                                    {
                                        dynamicClass = "orange_bg_color";
                                    }else{
                                        dynamicClass = "red_bg_color";
                                    }
                                    return (
                                                <span key={status.id} onClick={()=>filterByStatus(status.id)}><span className={`default_bg_color ${dynamicClass}`}> </span> <b>{status.status}</b></span>
                                        )
                                })
                            }
                            </div>                               
                            </div>
                            <div className="col-sm-6 text-right time-select">
                                
                                <div className="row">
                                    <div className="col-sm-12">
                                        <button className="btn btn-default" onClick={()=>todayYesterday('yesterday')}>Yesterday</button>
                                        <button className="btn btn-default" style={{marginLeft:"4px"}} onClick={()=>todayYesterday('today')}>Today</button>
                                        <button className="btn btn-default" style={{marginLeft:"4px"}} onClick={()=>todayYesterday('all')}>All</button>
                                        <DatePicker 
                                        value={date}
                                        placeholderText="Select date.."
                                        onChange={(e)=>searchDate(e)}
                                      />
                                    </div>
                                    
                                </div>
                                
                            </div>
                            </div>
                                
                            </div>

                            <div className={(!loading && products.length > 0) ? "grid_items" : "row"}>
                            {
                                products !==null &&
                                loading ? 
                                <div style={{textAlign:'center',marginTop:'70px'}}>
                                    <img src={loader} alt="loader" />
                                </div>
                                 :
                                products.length > 0 ?
                                products.map((product,index)=>{
                                return(<OrderList key={index} product={product} shops={shops} date={date} onClick={()=>getProductDetails(product.master_id)} />)

                                })
                                :
                                <div className='well mt-10'>
                                   {error? error : ' No Record Found'}
                                </div>
                            }

                            </div>
                    </div>
                </div>
            </div>
        </section>
	</React.Fragment>
	);

}
export default Content;