import React from 'react';
import './App.css';
import {HashRouter as Router} from 'react-router-dom';
import { Route, Switch,withRouter } from 'react-router-dom';
import Home from './container/Home';
import Maps from './container/Maps';
import Login from './container/Login';
import Orders from './container/Orders';
import Order from './container/Order';
import Users from './container/Users';
import User from './container/User';
import ToExcel from './container/ToExcelFile';
import Confirm from './component/ConfirmAert';
function App() {
  return (
    <React.Fragment>
      	<Router>
      	<Switch>
			<Route path="/home" component={withRouter(Home)}/>
			<Route exact path="/" component={Login}/>
      <Route path="/map" component={Maps}/>
      <Route path="/orders" component={Orders}/>
      <Route path="/order" component={Order}/>
      <Route path="/users" component={Users}/>
      <Route path="/user" component={User}/>
      <Route path="/excel" component={ToExcel}/>
			<Route path="/confirm" component={Confirm}/>
		</Switch>	
		</Router>
    </React.Fragment>
  );
}

export default App;
