import React, {Component} from 'react';
import Header from '../component/Header';
import Content from '../component/Content';
import Footer from '../component/Footer';
import {Redirect} from 'react-router-dom';
class Home extends Component {
	constructor(){
    super();
    this.state = {
        redirectToReferrer: false,
    };
}
componentDidMount(){
	
}
componentWillMount() {
	if(sessionStorage.getItem("userData")){
		this.getUserData();
	}
	else{
		this.setState({redirectToReferrer: true});
	}
}
getUserData() {
	JSON.parse(sessionStorage.getItem("userData"));

}	
render(){
if (this.state.redirectToReferrer) {
  return (<Redirect to={"/" }/>)
}
return(

<React.Fragment>
	<div id="container-fluid">
		<Header />
		<Content />
		<Footer />
	</div>
</React.Fragment>
	)
}
}
export default Home;