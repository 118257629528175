import React,{useState,useEffect} from 'react'
import $ from 'jquery';
const OrderList = ({product,onClick,shops,date}) => {
	const [sec, setSec] = useState(null);
	const [min, setMin] = useState(null);
	const [hrs, setHrs] = useState(null);
	const [days, setDays] = useState(null);
	// const [shops, setShops] = useState([]);
	useEffect(() => {
        // getAll();
        $(".popup_inner btn-info,.popup-overlay").click(function(){
            $(".popup-overlay,.popup_inner").fadeOut();
        });
        
        const interval = setInterval(() => {
            timeCalculate();
        }, 1000);

        return () => clearInterval(interval);
    }, [])

	function timeCalculate()
	{
	 	var d, h, m, s;
	 	const date1 = new Date(product.datetime);
        const date2 = new Date();
        const diffTime = Math.abs(date2 - date1);        
        s = Math.floor(diffTime / 1000);
    	m = Math.floor(s / 60);
    	s = s % 60;
    	h = Math.floor(m / 60);
    	m = m % 60;
    	d = Math.floor(h / 24);
    	h = h % 24;
    	setSec(s);
        setMin(m);
        setHrs(h);
        setDays(d);
	}
  //       let blinker = '';
  //       let blinkCondition = min >= 20 || hrs >0 || days > 0 
  //       if(blinkCondition && product.status_id==='0')
  //       {
  //           blinker = 'blinker';
  //       }
		// else if( blinkCondition && product.status_id==='1') 
		// {
		// 	 blinker = "blinker";
		// }else if(blinkCondition && product.status_id==='2')
  //       {
  //           blinker = 'blinker';
  //       }
        
  //       else if(blinkCondition && product.status_id==='3')
  //       {
  //           blinker = 'blinker';
  //       }
		// let selectedClass = '';
  //       if(product.status_id==='0')
  //       {
  //           selectedClass = 'full_box_red';
  //       }
  //       else if(product.status_id==='1')
  //       {
  //           selectedClass = 'full_box_red';
  //       }
  //       else if(product.status_id==='2')
  //       {
  //           selectedClass = 'full_box_orange';
  //       }
  //       else if(product.status_id==='3')
  //       {
  //           selectedClass = 'full_box_yellow';
  //       }
  //       else if(product.status_id==='4')
  //       {
  //           selectedClass = 'full_box_green';
  //       }
  //       else if(product.status_id==='5')
  //       {
  //           selectedClass = 'full_box_blue';
  //       }
        let blinker = '';
        let blinkCondition = min >= 20 || hrs >0 || days > 0 
        if(blinkCondition && product.assign_to==="0")
        {
            blinker = 'blinker';
        }

        let selectedClass = '';

        if(product.status_id==='1')
        {

            selectedClass = 'full_box_red';

        }
        else if(product.status_id==='2')
        {
            selectedClass = 'full_box_green';
        }
        else if(product.status_id==='3')
        {
            selectedClass = 'full_box_blue';
        }
        else if(product.status_id==='4')
        {
            selectedClass = 'full_box_orange';
        }
        else 
        {
            selectedClass = 'full_box_red';
        }

        const checkDate = date;

    return (		
	<div>
        {
        	
        	(product.master_id !==null)&&
            	<div className="products-items" onClick={onClick}>
                    <div className={`full_box_bg ${selectedClass} ${blinker}`}>
                        <div className="row">
                        	<div className="col-sm-5 sidegap">
                       	 		<b>{(product.time_selection)?product.time_selection:'--'}</b>
                        	</div>
                        	<div className="col-sm-7 shop_no">
                                <b>{(product.username)?product.username:''}</b>
                            </div>
                        </div>
                        <div className="order_no">
                            <h4>{product.master_id}</h4>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 time">
                                <div id="timer">
                                    {
                                        (checkDate==='today' || checkDate===null) ?
                                        <div>
                                            <div>
                                                {hrs}<span>H</span>
                                            </div>
                                            <div>
                                                {min}<span>M</span>
                                            </div>
                                            <div>
                                                {sec}<span>S</span>
                                            </div> 
                                        </div>
                                        :
                                        <div>
                                            <div>
                                                {days}<span>D</span>
                                            </div>
                                            <div>
                                                {hrs}<span>H</span>
                                            </div>
                                            <div>   
                                                {min}<span>M</span>
                                            </div>
                                            <div>
                                                {sec}<span>S</span>
                                            </div> 
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        }
                        
	</div>
	)
}

export default OrderList;